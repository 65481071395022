import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <div className="space-y-4">
                <p>
                    <strong>
                        Et presentem el millor curs de reforç per preparar l'examen de Drets Humans.
                    </strong>
                </p>

                <p>
                    Un any més, torna una nova edició i ja s'ha convertit en una formació molt
                    necessària any rere any.
                </p>

                <p>
                    <strong>Un curs de 3 hores en directe</strong> a través de <strong>ZOOM</strong>{" "}
                    amb una de les millors docents.
                </p>

                <p>
                    Durant el curs, ens centrarem en els <strong>conceptes clau</strong> i
                    susceptibles de sortir a l'examen.
                </p>

                <p>
                    El curs està basat en el <strong>temari oficial de la 38a promoció</strong>.
                </p>

                <p>
                    <strong>Dissabte 11 de gener</strong>
                    <br />
                    De 18.00 a 21.00h
                </p>

                <p>
                    <strong>El repàs final</strong> per treure bona nota el dia de l'examen oficial.
                </p>

                <p>
                    El curs és en directe i <strong>quedarà gravat fins al dia de l'examen</strong>.
                </p>
            </div>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="Domina els Drets Humans amb el curs definitiu de 3 hores en directe: prepara't per triomfar a l'examen oficial!"
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};
export default Product;
